import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {listReservationLocations} from '@wix/ambassador-table-reservations-v1-reservation-location/http'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {getDataFromLocalStorage, setDataToLocalStorage} from '../utils/dataCapsule'
import {setSsrWarmupData, getSsrWarmupData} from '../utils/warmupData'

const CACHE_KEY = 'tr-reservationLocations'
const SSR_WARMUP_KEY = 'tr-reservationLocations-ssr-warmup'

const getReservationLocations = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationLocation[]> => {
  const cachedReservationLocations = getDataFromLocalStorage<ReservationLocation[]>(
    flowAPI,
    CACHE_KEY,
  )
  const ssrWarmupData = getSsrWarmupData<ReservationLocation[]>(flowAPI, SSR_WARMUP_KEY)

  // client cache
  if (cachedReservationLocations) {
    return cachedReservationLocations
    // ssr cache
  } else if (ssrWarmupData) {
    return ssrWarmupData
  } else {
    const req = listReservationLocations({})
    const res = await flowAPI.httpClient.request(req)

    const reservationLocationsFromApi =
      res?.data?.reservationLocations?.filter(({archived}) => !archived) ?? []

    setDataToLocalStorage(flowAPI, CACHE_KEY, reservationLocationsFromApi)
    setSsrWarmupData(flowAPI, SSR_WARMUP_KEY, reservationLocationsFromApi)
    return reservationLocationsFromApi
  }
}

export const reservationLocationsService = {
  getReservationLocations,
}
