import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {getMyMember} from '@wix/ambassador-members-v1-member/http'
import {Member, Set} from '@wix/ambassador-members-v1-member/types'

import {getDataFromLocalStorage, setDataToLocalStorage} from '../utils/dataCapsule'

const CACHE_KEY = 'tr-currentMember'

export const getCurrentMember = async (flowAPI: ControllerFlowAPI) => {
  const cachedCurrentMember = getDataFromLocalStorage<Member>(flowAPI, CACHE_KEY)

  // If cached value is empty but not null
  // it means a previous request failed and user is not logged in
  if (cachedCurrentMember !== null) {
    return cachedCurrentMember || undefined
  }

  try {
    const currentMemberResponse = await flowAPI.httpClient.request(
      getMyMember({fieldSet: Set.FULL}),
    )
    setDataToLocalStorage(flowAPI, CACHE_KEY, currentMemberResponse.data?.member)
    return currentMemberResponse.data?.member
  } catch (error) {
    // Set cache to empty string to signify that the request failed.
    setDataToLocalStorage(flowAPI, CACHE_KEY, '')
    return undefined
  }
}
